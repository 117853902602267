import graphql from "babel-plugin-relay/macro";
import { Record } from "immutable";
import { readInlineData } from "react-relay";
import { asID, fromCustomerId, fromDealerId } from "securecom-graphql/client";

export const createDealer = Record({
  id: 0,
  address1: "",
  address2: "",
  name: "",
  city: "",
  country: "",
  email: "",
  facebook: "",
  logoUrl: "",
  phone1: "",
  phone2: "",
  postalCode: "",
  state: "",
  twitter: "",
  storeUserCodes: false,
  customerId: 0,
  distributionSubscriber: false,
});

export const createDealerFromJson = (json) =>
  createDealer({
    id: json.id,
    address1: json.address_1,
    address2: json.address_2,
    name: json.name,
    city: json.city,
    country: json.country,
    email: json.email,
    facebook: json.facebook,
    logoUrl: json.website_logo,
    phone1: json.phone_1,
    phone2: json.phone_2,
    postalCode: json.postal_code,
    state: json.state,
    twitter: json.twitter,
    storeUserCodes: json.store_user_codes,
    customerId: json.customerId,
    distributionSubscriber: json.distribution_subscriber,
  });

/**
 * @param { import("./__generated__/dealerModel_customer.graphql").dealerModel_customer$key } key
 * @returns {ReturnType<createDealer>}
 */
export const convertGqlDataToReduxData = (key) => {
  const data = readInlineData(
    graphql`
      fragment dealerModel_customer on Customer @inline {
        id
        dealer {
          id
          address1
          address2
          name
          city
          country
          email
          facebook
          websiteLogoUrl
          phone1
          phone2
          postalCode
          state
          twitter
          storeUserCodes
        }
      }
    `,
    key
  );
  const { dealer } = data;

  return createDealer({
    id: Number(fromDealerId(asID(dealer.id)).dealerId),
    address1: dealer.address1,
    address2: dealer.address2,
    name: dealer.name,
    city: dealer.city,
    country: dealer.country,
    email: dealer.email,
    facebook: dealer.facebook,
    logoUrl: dealer.websiteLogoUrl,
    phone1: dealer.phone1,
    phone2: dealer.phone2,
    postalCode: dealer.postalCode,
    state: dealer.state,
    twitter: dealer.twitter,
    storeUserCodes: dealer.storeUserCodes,
    customerId: Number(fromCustomerId(asID(data.id)).customerId),
  });
};
