/**
 *
 * systemUser model
 * @author Chad Watson
 *
 * A systemUser is a combination of a Person record and its associated Credential records combined with their associated UserCode records.
 * Managing these different kinds of records separately would be very cumbersome throughout the lifecycle of the app, which is why we
 * consolidate all of the associations into a single "User" model.
 *
 * This module serves as the translator between how the API understands the data and how our application understands the data.
 * See `makeUserFromJson` to see how our app understands the data.
 * See `makePersonJson`, `makeCredentialJson`, `makeXtUserCodeJson`, and `makeXrUserCodeJson` to see how the API understands our application data.
 *
 */

import { toUpperCase } from "common/utils/universal/string";
import Maybe from "data.maybe";
import { List, Map, OrderedSet, Record, fromJS } from "immutable";
import moment from "moment";
import {
  T,
  always,
  applySpec,
  both,
  complement,
  compose,
  cond,
  converge,
  curry,
  defaultTo,
  either,
  equals,
  has,
  head,
  ifElse,
  invoker,
  isEmpty,
  isNil,
  join,
  last,
  map,
  memoizeWith,
  nth,
  nthArg,
  of,
  path,
  prop,
  range,
  reduce,
  split,
  tail,
  toString,
  unless,
  when,
} from "ramda";
import {
  addParamToUrl,
  immutableApplySpec,
  immutableFirst,
  immutableGet,
  immutableGetIn,
  immutableMerge,
  immutablePush,
  parseStringList,
  safeToInt,
  toInt,
} from "utils";
import { toISOString } from "utils/dates";
import { boolToYesNo, yesNoToBool } from "utils/models";
import { PHOTO_SIZES, getSizedPhotoUrl } from "utils/photos";

export const XT_USER_AUTHORITY_KEYS = {
  MASTER: "master",
  ARM_ONLY: "armOnly",
  TEMP: "temporary",
};

export const CODE_TYPES = {
  PIN: "pin",
  BADGE: "badge",
  BADGE_GREEN: "badge_green",
  BADGE_YELLOW: "badge_yellow",
  FOB: "fob",
  MOBILE: "mobile",
  MOBILE_GREEN: "mobile_green",
  MOBILE_YELLOW: "mobile_yellow",
  UNKNOWN: "unknown",
};

export const TRANSLATED_CODE_TYPES = {
  PIN: "Code",
  BADGE: "Credential",
  FOB: "Fob",
  MOBILE: "Mobile Credential",
  UNKNOWN: "Unknown",
};

export const fromRawCredentialTypeToTranslatedEnum = (type) => {
  switch (type) {
    case "pin":
      return TRANSLATED_CODE_TYPES.PIN;
    case "badge":
      return TRANSLATED_CODE_TYPES.BADGE;
    case "fob":
      return TRANSLATED_CODE_TYPES.FOB;
    case "mobile":
      return TRANSLATED_CODE_TYPES.MOBILE;
    default:
      return TRANSLATED_CODE_TYPES.PIN;
  }
};

export const DEFAULT_XT_NUMBER_RANGE = OrderedSet(range(2, 31));
export const XTLP_NUMBER_RANGE = OrderedSet(range(2, 100));
export const XT50_NUMBER_RANGE = OrderedSet(range(2, 100));
export const CELLCOM_SL_NUMBER_RANGE = OrderedSet(range(2, 21));
export const CELLCOM_EX_NUMBER_RANGE = OrderedSet(range(2, 21));
export const ICOM_SL_NUMBER_RANGE = OrderedSet(range(2, 21));
export const XR_NUMBER_RANGE = OrderedSet(range(2, 10000));
export const ECP_NUMBER_RANGE = OrderedSet(range(2, 21)); // ECP panels with firmware version <= 192 can not edit user #2
export const DSC_NUMBER_RANGE = OrderedSet(range(2, 21)); // DSC panels with firmware version <= 192 can not edit user #40
export const TAKEOVER_EXTENDED_NUMBER_RANGE = OrderedSet(range(2, 100)); // Takeover modules with firmware version >= 194
export const XF_NUMBER_RANGE = OrderedSet(range(1, 11));
export const XT75_NUMBER_RANGE = OrderedSet(range(2, 201));

const tempDateFromJson = prop("temp_date");
const expireTimeFromJson = prop("expiretime");
const nameFromJson = compose(defaultTo(""), prop("name"));
const numberFromJson = compose(ifElse(isNil, always(2), toInt), prop("number"));
const codeFromJson = always("");
const appUserIdFromJson = compose(defaultTo(null), prop("user_id"));
const credentialIdFromJson = compose(
  when(complement(isNil), toString),
  defaultTo(null),
  prop("credential_id")
);

const parseTranslatedCodeName = memoizeWith(
  immutableGet("name"),
  compose(split(" "), immutableGet("name"))
);

const firstNameFromCode = compose(nth(0), parseTranslatedCodeName);

const lastNameFromCode = compose(join(" "), tail, parseTranslatedCodeName);

const getTranslatedProfile = (index) =>
  compose(
    defaultTo(""),
    unless(isNil, toString),
    immutableGetIn(["profiles", index])
  );

const getTranslatedGroup = (index) =>
  compose(
    defaultTo(""),
    unless(isNil, toString),
    immutableGetIn(["groups", index])
  );

const expirationToJson = compose(
  unless(isNil, invoker(0, "toISOString")),
  immutableGet("expiration")
);
const parseAreas = compose(map(toInt), parseStringList);

const makeExpirationMoment = (userCode) => {
  const tempDate = moment.utc(userCode.temp_date, "DDMMYY");
  const expireTime = moment.utc(userCode.expiretime, "hh:mm a");

  return moment.utc({
    year: tempDate.year(),
    month: tempDate.month(),
    date: tempDate.date(),
    hours: expireTime.hours(),
    minutes: expireTime.minutes(),
    seconds: expireTime.seconds(),
  });
};

const masterUserLevel = always(9);
const standardUserLevel = always(6);
export const masterXfUserLevel = always(1);
export const standardXfUserLevel = always(2);

export const ecpMasterUser = always(2);
export const isEcpMasterUser = (user) =>
  user.get("codes").some((code) => code.get("number") === ecpMasterUser());

export const dscMasterUser = always(40);
export const isDscMasterUser = (user) =>
  user.get("codes").some((code) => code.get("number") === dscMasterUser());

/**
 * From JSON models
 */
const createPersonNote = Record({
  id: null,
  note: "",
});
export const makeUserCodeFromJson = immutableApplySpec({
  name: nameFromJson,
  number: numberFromJson,
  code: codeFromJson,
  appUserId: appUserIdFromJson,
  credentialId: credentialIdFromJson,
  active: compose(yesNoToBool, defaultTo("Y"), prop("active")),
  expiration: ifElse(
    both(tempDateFromJson, expireTimeFromJson),
    makeExpirationMoment,
    always(null)
  ),
  profiles: (userCode) =>
    [
      userCode.profile1,
      userCode.profile2,
      userCode.profile3,
      userCode.profile4,
    ].reduce(
      (acc, profile) =>
        Maybe.fromNullable(profile)
          .chain(safeToInt)
          .map((profile) => acc.push(profile))
          .getOrElse(acc),
      List()
    ),
  areas: compose(
    ifElse(isEmpty, always([]), parseAreas),
    defaultTo(""),
    prop("areas")
  ),
  master: compose(
    equals(masterUserLevel()),
    toInt,
    defaultTo(standardUserLevel()),
    prop("user_level")
  ),
  armOnly: compose(defaultTo(false), prop("arm_only")),
  // For XT Users
  temporary: compose(defaultTo(false), prop("temp")),
  sendToLocks: compose(yesNoToBool, defaultTo("0"), prop("snd_to_lks")),
  // tempUser is only for XR v191+ temp users
  // Do not use moment.utc() for start or end dates so usage matches `/components/UserForm/Code.js`. Otherwise, date will be off by one. See https://jira.dmp.com/browse/VKB-1200.
  tempUser: (json) =>
    Maybe.fromNullable(json.temp_user).map((temporary) =>
      Map({
        temporary: yesNoToBool(temporary),
        startDate: Maybe.fromNullable(json.start_date)
          .chain((dateString) =>
            dateString
              ? Maybe.of(moment(dateString, "DDMMYY"))
              : Maybe.Nothing()
          )
          .chain((date) => (date.isValid() ? Maybe.of(date) : Maybe.Nothing())),
        endDate: Maybe.fromNullable(json.temp_date)
          .chain((dateString) =>
            dateString
              ? Maybe.of(moment(dateString, "DDMMYY"))
              : Maybe.Nothing()
          )
          .chain((date) => (date.isValid() ? Maybe.of(date) : Maybe.Nothing())),
      })
    ),
});

export const makeCredentialFromJson = immutableApplySpec({
  id: compose(unless(isNil, toString), prop("id")),
  type: compose(defaultTo(CODE_TYPES.PIN), prop("credential_type")),
  userId: compose(
    when(complement(isNil), toString),
    defaultTo(null),
    prop("person_id")
  ),
  cardNumber: compose(defaultTo(""), prop("card_number")),
  userCodesByPanelId: compose(
    reduce(
      (acc, item) => acc.set(item.panel_id, parseInt(item.number, 10)),
      Map()
    ),
    defaultTo([]),
    prop("user_codes")
  ),
});

export const makePersonFromJson = immutableApplySpec({
  id: compose(unless(isNil, toString), prop("id")),
  firstName: compose(defaultTo(""), prop("first_name")),
  lastName: compose(defaultTo(""), prop("last_name")),
  visibleLogin: compose(when(isNil, always("")), prop("visible_login")),
  emailAddress: compose(when(isNil, always("")), prop("email_address")),
  phoneNumber: compose(when(isNil, always("")), prop("phone_number")),
  note: compose(
    createPersonNote,
    when(isNil, always({ id: null, note: "" })),
    prop("note")
  ),
  photoUrl: compose(
    getSizedPhotoUrl(PHOTO_SIZES.mobile_medium),
    prop("photo_url")
  ),
  photoThumb: compose(getSizedPhotoUrl(PHOTO_SIZES.thumb), prop("photo_url")),
});

const vkErrorsFromJson = compose(fromJS, map(head), path(["errors"]));
// TODO: there are probably other potential errors that could come back

const jobErrorsFromJson = immutableApplySpec({
  code: path(["job", "details", "message"]),
});
const fallbackError = immutableApplySpec({
  base: always(["An error occured"]),
});

// Setting any as fromJS on vkErrorsFromJSON
export const userCodeErrorsFromJson = cond([
  [has("job"), jobErrorsFromJson],
  [has("errors"), vkErrorsFromJson],
  [T, fallbackError],
]);

/**
 * Combiners
 */
const isNilOrEmpty = either(isNil, isEmpty);

const makeUser = immutableApplySpec({
  id: compose(immutableGet("id"), prop("person")),
  visibleLogin: compose(immutableGet("visibleLogin"), prop("person")),
  emailAddress: compose(immutableGet("emailAddress"), prop("person")),
  phoneNumber: compose(immutableGet("phoneNumber"), prop("person")),
  note: compose(createPersonNote, immutableGet("note"), prop("person")),
  firstName: compose(
    ifElse(
      compose(isNilOrEmpty, prop("personFirstName")),
      prop("firstCodeFirstName"),
      prop("personFirstName")
    ),
    applySpec({
      personFirstName: compose(immutableGet("firstName"), prop("person")),
      firstCodeFirstName: compose(
        firstNameFromCode,
        immutableFirst,
        prop("codes")
      ),
    })
  ),
  lastName: compose(
    ifElse(
      compose(isNilOrEmpty, prop("personLastName")),
      ({ personFirstName, firstCodeLastName }) => {
        const personFirstNameArray = personFirstName
          .split(" ")
          .map(toUpperCase);
        return last(personFirstNameArray) !== firstCodeLastName.toUpperCase()
          ? firstCodeLastName
          : "";
      },
      prop("personLastName")
    ),
    applySpec({
      personLastName: compose(immutableGet("lastName"), prop("person")),
      firstCodeLastName: compose(
        lastNameFromCode,
        immutableFirst,
        prop("codes")
      ),
      personFirstName: compose(immutableGet("firstName"), prop("person")),
    })
  ),
  photoUrl: ({ person, timestamp }) =>
    Maybe.fromNullable(person.get("photoUrl"))
      .map(addParamToUrl("timestamp", timestamp))
      .getOrElse(null),
  photoThumb: ({ person, timestamp }) =>
    Maybe.fromNullable(person.get("photoThumb"))
      .map(addParamToUrl("timestamp", timestamp))
      .getOrElse(null),
  codes: prop("codes"),
  lastUpdatedAt: prop("timestamp"),
});

const makeUserFromOrphanedCode = immutableApplySpec({
  id: always(null),
  firstName: firstNameFromCode,
  lastName: lastNameFromCode,
  photoUrl: always(null),
  codes: compose(List, of),
  lastUpdatedAt: nthArg(1),
  note: always(createPersonNote({ id: null, note: "" })),
});

export const makeUsers = ({ persons, userCodes, credentials, timestamp }) =>
  userCodes
    .reduce((acc, userCode) => {
      const credential = credentials.get(userCode.get("credentialId"));
      const code = userCode.merge(
        credential
          ? credential.update("userId", (userId) =>
              userId && !persons.get(userId) ? null : userId
            )
          : makeCredentialFromJson({})
      );
      const person = persons.get(code.get("userId"));

      if (person && acc.getIn(["byUserId", person.get("id")])) {
        return acc.updateIn(
          ["byUserId", person.get("id"), "codes"],
          immutablePush(code)
        );
      }

      if (person) {
        return acc.setIn(
          ["byUserId", person.get("id")],
          makeUser({ person, codes: List.of(code), timestamp })
        );
      }

      return acc.setIn(
        ["orphanedCodesById", code.get("id")],
        makeUserFromOrphanedCode(code, timestamp)
      );
    }, Map({ byUserId: Map(), orphanedCodesById: Map() }))
    .toObject();

export const makeNewCode = converge(immutableMerge, [
  makeCredentialFromJson,
  makeUserCodeFromJson,
]);

export const makeNewUser = compose(
  makeUser,
  applySpec({
    person: always(makePersonFromJson({})),
    codes: compose(List, Array.of, makeNewCode),
  })
);

/**
 * To JSON models
 */
export const makeXrUserCodeJson = applySpec({
  number: immutableGet("number"),
  name: compose(defaultTo(""), immutableGet("name")),
  code: compose(when(isEmpty, always(undefined)), immutableGet("code")),
  user_pin: compose(when(isEmpty, always(undefined)), immutableGet("pin")),
  user_id: compose(defaultTo(undefined), immutableGet("appUserId")),
  profile1: getTranslatedProfile(0),
  profile2: getTranslatedProfile(1),
  profile3: getTranslatedProfile(2),
  profile4: getTranslatedProfile(3),
  temp_date: (userCode) =>
    userCode
      .get("tempUser")
      .map((tempUser) =>
        tempUser.get("temporary")
          ? tempUser.get("endDate").map(toISOString).getOrElse("")
          : ""
      )
      .orElse(() => Maybe.of(expirationToJson(userCode)))
      .getOrElse(undefined),
  expiretime: expirationToJson,
  credential_id: immutableGet("id"),
  active: compose(boolToYesNo, immutableGet("active")),
  snd_to_lks: compose(boolToYesNo, immutableGet("sendToLocks")),
  temp_user: (userCode) =>
    userCode
      .get("tempUser")
      .map((tempUser) => boolToYesNo(tempUser.get("temporary")))
      .getOrElse(undefined),
  start_date: (userCode) =>
    userCode
      .get("tempUser")
      .map((tempUser) =>
        tempUser.get("temporary")
          ? tempUser.get("startDate").map(toISOString).getOrElse("")
          : ""
      )
      .getOrElse(undefined),
});

export const makeXtUserCodeJson = applySpec({
  number: immutableGet("number"),
  name: compose(defaultTo(""), immutableGet("name")),
  code: compose(when(isEmpty, always(undefined)), immutableGet("code")),
  user_id: compose(defaultTo(undefined), immutableGet("appUserId")),
  areas: compose(join(","), immutableGet("areas")),
  arm_only: immutableGet("armOnly"),
  temp: immutableGet("temporary"),
  user_level: ifElse(
    immutableGet("master"),
    masterUserLevel,
    standardUserLevel
  ),
  snd_to_lks: compose(boolToYesNo, immutableGet("sendToLocks")),
  credential_id: immutableGet("id"),
  active: compose(boolToYesNo, immutableGet("active")),
});

export const makeXf6UserCodeJson = applySpec({
  number: immutableGet("number"),
  name: compose(defaultTo(""), immutableGet("name")),
  code: compose(when(isEmpty, always(undefined)), immutableGet("code")),
  user_id: compose(defaultTo(undefined), immutableGet("appUserId")),
  areas: [],
  arm_only: false,
  temp: immutableGet("temporary"),
  profile1: getTranslatedProfile(0),
  profile2: getTranslatedProfile(1),
  profile3: getTranslatedProfile(2),
  profile4: getTranslatedProfile(3),
  snd_to_lks: (boolToYesNo, false),
  credential_id: immutableGet("id"),
  active: compose(boolToYesNo, immutableGet("active")),
});

export const makeXt75UserCodeJson = applySpec({
  number: immutableGet("number"),
  name: compose(defaultTo(""), immutableGet("name")),
  code: compose(when(isEmpty, always(undefined)), immutableGet("code")),
  user_pin: compose(when(isEmpty, always(undefined)), immutableGet("pin")),
  user_id: compose(defaultTo(undefined), immutableGet("appUserId")),
  group1: getTranslatedGroup(1),
  group2: getTranslatedGroup(2),
  group3: getTranslatedGroup(3),
  group4: getTranslatedGroup(4),
  group5: getTranslatedGroup(5),
  group6: getTranslatedGroup(6),
  group7: getTranslatedGroup(7),
  group8: getTranslatedGroup(8),
  group9: getTranslatedGroup(9),
  group10: getTranslatedGroup(10),
  temp_date: (userCode) =>
    userCode
      .get("tempUser")
      .map((tempUser) =>
        tempUser.get("temporary")
          ? tempUser.get("endDate").map(toISOString).getOrElse("")
          : ""
      )
      .orElse(() => Maybe.of(expirationToJson(userCode)))
      .getOrElse(undefined),
  expiretime: expirationToJson,
  credential_id: immutableGet("id"),
  active: compose(boolToYesNo, immutableGet("active")),
  snd_to_lks: compose(boolToYesNo, immutableGet("sendToLocks")),
  temp_user: (userCode) =>
    userCode
      .get("tempUser")
      .map((tempUser) => boolToYesNo(tempUser.get("temporary")))
      .getOrElse(undefined),
  start_date: (userCode) =>
    userCode
      .get("tempUser")
      .map((tempUser) =>
        tempUser.get("temporary")
          ? tempUser.get("startDate").map(toISOString).getOrElse("")
          : ""
      )
      .getOrElse(undefined),
});

export const makeCredentialJson = applySpec({
  id: immutableGet("id"),
  credential_type: immutableGet("type"),
  security_code: immutableGet("code"),
  card_number: immutableGet("cardNumber"),
  person_id: immutableGet("userId"),
});

export const makePersonJson = applySpec({
  id: immutableGet("id"),
  first_name: immutableGet("firstName"),
  last_name: immutableGet("lastName"),
  email_address: compose(
    when(isEmpty, always("")),
    immutableGet("emailAddress")
  ),
  phone_number: compose(when(isEmpty, always("")), immutableGet("phoneNumber")),
});

export const parseUser = curry((system, user) =>
  user.get("codes").reduce(
    ({ credentials, userCodes }, code) => ({
      credentials: credentials.push(makeCredentialJson(code)),
      userCodes: userCodes.push(
        system.get("isXf")
          ? makeXf6UserCodeJson(code)
          : system.get("isXT")
          ? makeXtUserCodeJson(code)
          : system.get("isXT75")
          ? makeXt75UserCodeJson(code)
          : makeXrUserCodeJson(code)
      ),
    }),
    {
      person: makePersonJson(user),
      credentials: List(),
      userCodes: List(),
    }
  )
);
