import Maybe from "data.maybe";
import { always, compose, curry, map, nth, split, tail, test } from "ramda";
import { fromSiteId } from "securecom-graphql/client";
import { ensureNoTrailingSlash, memoizeLatest } from "utils";

export const SYSTEMS_PATH = "/systems";
export const SITES_PATH = "/sites";
export const SYSTEM_PATH_SEGMENTS = {
  DASHBOARD: "",
  USERS: "users",
  PROFILES: "profiles",
  GROUPS: "groups",
  SCHEDULES: "schedules",
  FAVORITES: "favorites",
  VIDEO: "video",
  THERMOSTATS: "thermostats",
  DOORS: "doors",
  LIGHTS: "lights",
  OUTPUTS: "outputs",
  ACTIONS: "actions",
  EVENTS: "events",
  REPORTS: "reports",
  SETTINGS: "settings",
  MONITORING: "monitoring",
  NOTIFICATIONS: "notifications",
  PANIC: "panic",
};
export const systemRootRoute = (systemId) =>
  `${SYSTEMS_PATH}/${systemId.toString()}`;
export const systemChildRoute = curry(
  (childSegment, systemId) => `${systemRootRoute(systemId)}/${childSegment}`
);
/**
 * @type {{ [key in keyof typeof SYSTEM_PATH_SEGMENTS]: (systemId: number|string) => string }}
 */
export const SYSTEM_PATH_CREATORS = Object.keys(SYSTEM_PATH_SEGMENTS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: systemChildRoute(SYSTEM_PATH_SEGMENTS[key]),
  }),
  {}
);
export const systemRootPath = () =>
  window.location.pathname.split("/").slice(0, 3).join("/");
export const systemIdFromPathname = (pathname) =>
  Maybe.fromNullable(pathname.match(/\/systems\/(\d+)/)).map(([, systemId]) =>
    parseInt(systemId, 10)
  );
export const currentSystemSegment = () =>
  systemIdFromPathname(window.location.pathname);
export const currentSystemChildSegment = () =>
  window.location.pathname.split("/")[3] || "";
export const isSystemRoute = test(new RegExp(`^\\${SYSTEMS_PATH}`));
export const isVideoRoute = () =>
  currentSystemChildSegment() === SYSTEM_PATH_SEGMENTS.VIDEO;
export const ADMIN_PATH = "/admin";
export const SETTINGS_PATH = "/settings";
export const adminRootRoute = always(ADMIN_PATH);
export const adminChildRoute = (path) => [ADMIN_PATH, path].join("/");
export const ADMIN_ROUTE_SEGMENTS = {
  SETTINGS: "settings",
  APP_USERS: "app-users",
  USERS: "users",
  SCHEDULES: "schedules",
  REPORTS: "reports",
  SYSTEM_GROUPS: "system-groups",
  ARMING: "arming",
  STATUSES: "statuses",
  PROFILES: "profiles",
  EVENTS: "events",
};
export const ADMIN_ROUTES = map(adminChildRoute, ADMIN_ROUTE_SEGMENTS);
export const isAdminRoute = test(new RegExp(`^\\${ADMIN_PATH}`));
export const isSettingsRoute = test(new RegExp(`\\${SETTINGS_PATH}`));
export const currentAdminChildRoute = compose(nth(1), split("/"), tail);
export const hasChildRoute = compose(Boolean, currentAdminChildRoute);
export const TERMS_AND_CONDITIONS_PATH = "terms";
export const PRIVACY_POLICY_PATH = "privacy";
export const EU_TERMS_AND_CONDITIONS_PATH =
  "https://securecomwireless.com/privacy/vk-terms";
export const EU_PRIVACY_POLICY_PATH =
  "https://securecomwireless.com/privacy/privacy-policy";
export const createAdminAppUsersPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.APP_USERS}`;
export const createAdminUsersPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.USERS}`;
export const createAdminSchedulesPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.SCHEDULES}`;
export const createAdminReportsPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.REPORTS}`;
export const createAdminSystemGroupsPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.SYSTEM_GROUPS}`;
export const createAdminSettingsPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.SETTINGS}`;
export const createAdminArmingPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.ARMING}`;
export const createAdminStatusPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.STATUS}`;
export const createAdminEventsPath = (base) =>
  `${base}/${ADMIN_ROUTE_SEGMENTS.EVENTS}`;
export const SITE_PATH_SEGMENTS = {
  USERS: "users",
  DOORS: "doors",
  EVENTS: "events",
  GROUPS: "groups",
  RULES: "rules",
  REPORTS: "reports",
  NOTIFICATIONS: "notifications",
  VIDEO: "video",
  SETTINGS: "settings",
};
export const siteRootRoute = (siteId) => `${SITES_PATH}/${String(siteId)}`;
export const siteChildRoute = curry(
  (childSegment, siteId) => `${siteRootRoute(siteId)}/${childSegment}`
);
/**
 * @type {{ [key in keyof typeof SITE_PATH_SEGMENTS]: (siteId: number|string) => string }}
 */
export const SITE_PATH_CREATORS = Object.keys(SITE_PATH_SEGMENTS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: siteChildRoute(SITE_PATH_SEGMENTS[key]),
  }),
  {}
);
export const siteIdFromPathname = (pathname) =>
  Maybe.fromNullable(pathname.match(/\/sites\/(\d+)/))
    .map(([, siteId]) => parseInt(siteId, 10))
    .getOrElse(null);

export const isSiteRoute = test(new RegExp(`^\\${SITES_PATH}`));

export const currentSiteChildSegment = compose(nth(2), split("/"), tail);

export const sitePaths = memoizeLatest(
  (/** @type {string | number} */ siteId) => {
    const events = siteChildRoute(SITE_PATH_SEGMENTS.EVENTS, siteId);
    return {
      users: siteChildRoute(SITE_PATH_SEGMENTS.USERS, siteId),
      doors: siteChildRoute(SITE_PATH_SEGMENTS.DOORS, siteId),
      events,
      groups: siteChildRoute(SITE_PATH_SEGMENTS.GROUPS, siteId),
      rules: siteChildRoute(SITE_PATH_SEGMENTS.RULES, siteId),
      reports: `${events}/${SITE_PATH_SEGMENTS.REPORTS}`,
      notifications: `${events}/${SITE_PATH_SEGMENTS.NOTIFICATIONS}`,
      video: siteChildRoute(SITE_PATH_SEGMENTS.VIDEO, siteId),
      settings: siteChildRoute(SITE_PATH_SEGMENTS.SETTINGS, siteId),
    };
  }
);

export const sitePathsFromSite = (site) =>
  sitePaths(fromSiteId(site.id).siteId);

export const CREATE_PASSWORD_ROUTE = "/create-password";
export const CHANGE_PASSWORD_ROUTE = "/change-password";

/** @type {(pathname: string) => boolean} */
export const isCreatePasswordRoute = (pathname) =>
  ensureNoTrailingSlash(pathname) === CREATE_PASSWORD_ROUTE;

/** @type {(pathname: string) => boolean} */
export const isChangePasswordRoute = (pathname) =>
  ensureNoTrailingSlash(pathname) === CHANGE_PASSWORD_ROUTE;
